import React from "react";
import Container from "../container";

function Subtitle({ title }: { title: string }) {
  return (
    <h2 className="text-2xl sm:text-3xl font-extrabold tracking-tight uppercase">
      {title}
    </h2>
  );
}

export default function Hero() {
  return (
    <Container>
      <h1 className="text-white mb-4 text-5xl sm:text-6xl font-extrabold tracking-tight">
        Tom J. Watson
      </h1>
      <Subtitle title="Software Engineer" />
      <Subtitle title="Madrid, Spain" />
    </Container>
  );
}
