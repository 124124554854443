import React from "react";
import Container from "../container";
import Header from "../lib/header";
import Paragraph from "../lib/paragraph";

export default function About() {
  return (
    <Container>
      <>
        <Header title="About" />
        <Paragraph>
          Hi, I&apos;m Tom - software engineer and open source enthusiast. When
          I&apos;m not coding, you&apos;ll find me playing badminton, watching
          too many types of motorsport or sitting on a terrace somewhere in
          Madrid.
        </Paragraph>
      </>
    </Container>
  );
}
